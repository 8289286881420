import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import Seo from "../components/meta/Seo";
import ServiceSchema from "../components/meta/ServiceSchema";
import FaqSchema from "../components/meta/FaqSchema";
import Layout from "../components/common/Layout";
import HeaderBackground from "../components/blocks/HeaderBackground";
import SliceZone from "../components/common/SliceZone";
import ProductsList from "../components/blocks/ProductsList";
import { constructActiveDoc } from "../helpers";
import CtaChargespot from "../components/common/CtaChargespot";

const businessExclusive = ({ data }) => {
  if (!data) return null;

  const { settings, page, products } = data;
  const {
    title,
    description,
    image,
    image_mobile: imageMobile,
    body,
  } = page.data;

  const {
    come_chargespot_label: chargespotLabel,
    come_chargespot_link: chargespotLink,
  } = settings.data;

  return (
    <>
      <Seo page={page} settings={settings} itemsList={products} />
      <ServiceSchema page={page} settings={settings} />
      <FaqSchema body={body} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <HeaderBackground
          title={title}
          description={description}
          image={image}
          imageMobile={imageMobile}
        />
        <SliceZone slices={body} settings={settings} />
        <ProductsList products={products} />
        <div className="my-6 px-3 text-center">
          <CtaChargespot
            data={{ chargespotLabel, chargespotLink }}
            className="h5"
          />
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query businessExclusiveQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicChargespotBusinessExclusive(lang: { eq: $lang }) {
      ...prismicChargespotBusinessExclusiveFragment
    }
    products: allPrismicProduct(
      sort: { fields: data___title }
      filter: {
        lang: { eq: $lang }
        data: { business_exclusive: { eq: true } }
      }
    ) {
      edges {
        node {
          ...prismicProductFragment
        }
      }
    }
  }
`;

export default withPrismicPreview(businessExclusive);
